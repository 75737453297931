import { action, payload } from 'ts-action'
import { IModalEditShiftValues } from 'helpers/shiftHelpers/types'
import { IShift } from 'models/Shift'

// action for saving initialValues and values from Edit Shift Modal Form (QuickEditModal)
// when navigating to Full Edit Shift Page (when "Full Edit" button is clicked in QuickEditModal)
export const SaveEditShiftModalValues = action(
  'SaveEditShiftModalValues',
  payload<{
    editShiftModalInitialValues: IModalEditShiftValues
    editShiftModalValues: IModalEditShiftValues
  }>(),
)

// action for saving the original shift that we are editing
export const SaveOriginalShift = action('SaveOriginalShift', payload<IShift>())

export const OriginalShiftIsFetching = action(
  'OriginalShiftIsFetching',
  payload<boolean>(),
)

export const ClearFullEditShiftReducer = action('ClearFullEditShiftReducer')
