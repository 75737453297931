import { action, payload } from 'ts-action'
import { IApplication } from 'models/Application'
import { IError, IPayload } from 'models/Global'

export const SelectedShiftPendingApplicationsFetching = action(
  'SelectedShiftPendingApplicationsFetching',
  payload<boolean>(),
)

export const GetSelectedShiftPendingApplications = action(
  'GetSelectedShiftPendingApplications',
  payload<IPayload<IApplication[]>>(),
)

export const UpdateSelectedShiftPendingApplication = action(
  'UpdateSelectedShiftPendingApplication',
  payload<IPayload<IApplication>>(),
)

export const DeleteSelectedShiftPendingApplication = action(
  'DeleteSelectedShiftPendingApplication',
  payload<number>(),
)

export const DeleteSelectedShiftPendingApplicationByUserId = action(
  'DeleteSelectedShiftPendingApplicationByUserId',
  payload<number>(),
)

export const GetSelectedShiftPendingApplicationsErrors = action(
  'GetSelectedShiftPendingApplicationsErrors',
  payload<IError>(),
)

export const CleanSelectedShiftPendingApplications = action(
  'CleanSelectedShiftPendingApplications',
)
