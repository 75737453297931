import { action, payload } from 'ts-action'
import {
  IRequestApplication,
  IRequestApplicationUser,
  ISetRequestApplicationUserAvailabilityPayload,
} from 'models/RequestApplication'
import { IError, IPayload } from 'models/Global'
import { IShift } from 'models/Shift'

export const RequestApplicationsFetching = action(
  'RequestApplicationsFetching',
  payload<boolean>(),
)

export const GetRequestApplications = action(
  'GetRequestApplications',
  payload<IPayload<IRequestApplication[]>>(),
)

export const GetRequestApplicationUsers = action(
  'GetRequestApplicationUsers',
  payload<IPayload<IRequestApplicationUser[]>>(),
)

export const AddRequestApplication = action(
  'AddRequestApplication',
  payload<IPayload<IRequestApplication[]>>(),
)

export const UpdateRequestApplication = action(
  'UpdateRequestApplication',
  payload<IPayload<IRequestApplication>>(),
)

export const DeleteRequestApplication = action(
  'DeleteRequestApplication',
  payload<number>(),
)

export const GetRequestApplicationsErrors = action(
  'GetRequestApplicationsErrors',
  payload<IError>(),
)

export const DeleteRequestApplicationUser = action(
  'DeleteRequestApplicationUser',
  payload<number>(),
)

export const AddRequestApplicationUser = action(
  'AddRequestApplicationUser',
  payload<IRequestApplicationUser>(),
)

export const AddRequestApplicationShift = action(
  'AddRequestApplicationShift',
  payload<IShift>(),
)

export const ClearRequestApplicationShifts = action(
  'ClearRequestApplicationShifts',
)

export const UpdateRequestApplicationShift = action(
  'UpdateRequestApplicationShift',
  payload<IShift>(),
)

export const ClearRequestApplicationUsers = action(
  'ClearRequestApplicationUsers',
)

export const SetRequestApplicationUserAvailability = action(
  'SetRequestApplicationUserAvailability',
  payload<ISetRequestApplicationUserAvailabilityPayload>(),
)

export const SetRequestApplicationUserWasInvited = action(
  'SetRequestApplicationUserWasInvited',
  payload<number>(),
)
