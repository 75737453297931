import { action, payload } from 'ts-action'
import { IApplication } from 'models/Application'
import { IError, IPayload } from 'models/Global'

export const SelectedShiftAcceptedApplicationsFetching = action(
  'SelectedShiftAcceptedApplicationsFetching',
  payload<boolean>(),
)

export const GetSelectedShiftAcceptedApplications = action(
  'GetSelectedShiftAcceptedApplications',
  payload<IPayload<IApplication[]>>(),
)

export const UpdateSelectedShiftAcceptedApplication = action(
  'UpdateSelectedShiftAcceptedApplication',
  payload<IPayload<IApplication>>(),
)

export const DeleteSelectedShiftAcceptedApplication = action(
  'DeleteSelectedShiftAcceptedApplication',
  payload<number>(),
)

export const DeleteSelectedShiftAcceptedApplicationByUserId = action(
  'DeleteSelectedShiftAcceptedApplicationByUserId',
  payload<number>(),
)

export const GetSelectedShiftAcceptedApplicationsErrors = action(
  'GetSelectedShiftAcceptedApplicationsErrors',
  payload<IError>(),
)

export const CleanSelectedShiftAcceptedApplications = action(
  'CleanSelectedShiftAcceptedApplications',
)
