import { IFullEditShiftReducer, IShift } from 'models/Shift'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  SaveEditShiftModalValues,
  SaveOriginalShift,
  ClearFullEditShiftReducer,
  OriginalShiftIsFetching,
} from './actions'
import { IModalEditShiftValues } from 'helpers/shiftHelpers/types'

const initialState: IFullEditShiftReducer = {
  editShiftModalInitialValues: {} as IModalEditShiftValues,
  editShiftModalValues: {} as IModalEditShiftValues,
  originalShift: {} as IShift,
  originalShiftIsFetching: false,
}

export const fullEditShiftReducer = (
  state = initialState,
  action: Action,
): IFullEditShiftReducer => {
  if (isType(action, SaveEditShiftModalValues)) {
    const { payload } = action
    return {
      ...state,
      editShiftModalInitialValues: payload.editShiftModalInitialValues,
      editShiftModalValues: payload.editShiftModalValues,
    }
  }

  if (isType(action, SaveOriginalShift)) {
    const { payload } = action
    return {
      ...state,
      originalShift: payload,
    }
  }

  if (isType(action, OriginalShiftIsFetching)) {
    const { payload } = action
    return {
      ...state,
      originalShiftIsFetching: payload,
    }
  }

  if (isType(action, ClearFullEditShiftReducer)) {
    return initialState
  }

  return state
}
