import { IHiddenApplicationsReducer, IApplication } from 'models/Application'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { deleteFromArray } from 'helpers/helperFuncs'
import {
  GetHiddenApplications,
  HiddenApplicationsFetching,
  UnhideGroupHiddenApplication,
  UnhideHiddenApplication,
} from './actions'
import { AddFavorite, DeleteFavorite } from 'data/business/Favorite/actions'

const initialState: IHiddenApplicationsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const hiddenApplicationsReducer = (
  state = initialState,
  action: Action,
): IHiddenApplicationsReducer => {
  if (isType(action, GetHiddenApplications)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UnhideHiddenApplication)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IApplication>(data, payload.data.id)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, UnhideGroupHiddenApplication)) {
    const { payload } = action
    const { data } = state
    const formattedArray = data.filter(
      application => application?.group_application?.id !== payload.data.id,
    )
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, AddFavorite)) {
    const newApplications = state.data.map(application => {
      if (application.group_application) {
        application.group_application.applications.data.map(
          groupApplication => {
            if (
              groupApplication.applicant.id ===
              action.payload.data.model_favored.id
            ) {
              const modifiedApplication = groupApplication
              modifiedApplication.applicant.favorite = true
              modifiedApplication.applicant.favorite_id = action.payload.data.id
              return modifiedApplication
            }
            return groupApplication
          },
        )
      } else if (
        application.applicant.id === action.payload.data.model_favored.id
      ) {
        const modifiedApplication = application
        modifiedApplication.applicant.favorite = true
        modifiedApplication.applicant.favorite_id = action.payload.data.id
        return modifiedApplication
      }
      return application
    })
    return {
      ...state,
      data: newApplications,
    }
  }

  if (isType(action, DeleteFavorite)) {
    const newApplications = state.data.map(application => {
      if (application.group_application) {
        application.group_application.applications.data.map(
          groupApplication => {
            if (groupApplication.applicant.id === action.payload) {
              const modifiedApplication = groupApplication
              modifiedApplication.applicant.favorite = false
              modifiedApplication.applicant.favorite_id = null
              return modifiedApplication
            }
            return groupApplication
          },
        )
      } else if (application.applicant.id === action.payload) {
        const modifiedApplication = application
        modifiedApplication.applicant.favorite = false
        modifiedApplication.applicant.favorite_id = null
        return modifiedApplication
      }
      return application
    })
    return {
      ...state,
      data: newApplications,
    }
  }

  if (isType(action, HiddenApplicationsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  return state
}
