import { action, payload } from 'ts-action'
import { IModalDuplicateShiftValues } from 'helpers/shiftHelpers/types'
import { IShift } from 'models/Shift'

export const SaveDuplicateValues = action(
  'SaveDuplicateValues',
  payload<IModalDuplicateShiftValues>(),
)

export const SaveShift = action('SaveShift', payload<IShift>())

export const ClearDuplicateValues = action('ClearDuplicateValues')

export const ClearShift = action('ClearShift')
