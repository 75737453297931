import { IShift, IShiftValuesReducer } from 'models/Shift'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  SaveDuplicateValues,
  SaveShift,
  ClearDuplicateValues,
  ClearShift,
} from './actions'
import { IModalDuplicateShiftValues } from 'helpers/shiftHelpers/types'

const initialState: IShiftValuesReducer = {
  duplicateShiftValues: {} as IModalDuplicateShiftValues,
  shiftFromModal: {} as IShift,
}

export const shiftValuesReducer = (
  state = initialState,
  action: Action,
): IShiftValuesReducer => {
  if (isType(action, SaveDuplicateValues)) {
    const { payload } = action
    return {
      ...state,
      duplicateShiftValues: payload,
    }
  }
  if (isType(action, SaveShift)) {
    const { payload } = action
    return {
      ...state,
      shiftFromModal: payload,
    }
  }
  if (isType(action, ClearDuplicateValues)) {
    return {
      ...state,
      duplicateShiftValues: {} as IModalDuplicateShiftValues,
    }
  }
  if (isType(action, ClearShift)) {
    return {
      ...state,
      shiftFromModal: {} as IShift,
    }
  }
  return state
}
